'use client'

import { PropsWithChildren, Suspense, useEffect, useState } from 'react'
import { MultiSelectProvider } from '@/components/providers/functionality/MultiSelectProvider'
import { FirebaseProvider } from '@/components/providers/site/FirebaseProvider'
import { ImpersonationProvider } from '@/components/providers/site/ImpersonationProvider'
import { init } from '@/core/google-stackdriver'
import { useConfig } from '@/entity/site/useConfig'
import { initializeTranslations } from '@/core/i18n'
import icons from '@/style/icon-library'

export const RootProviders = ({ children }: PropsWithChildren) => {
  const config = useConfig()

  useEffect(() => {
    init(
      config.isDevelopment,
      config?.stackdriver,
      config.projectId,
      'Client Next'
    )
    icons.Init()
    initializeTranslations(true)
  }, [config.isDevelopment, config.stackdriver, config.projectId])

  return (
    <Suspense>
      <ImpersonationProvider>
        <FirebaseProvider>
          <MultiSelectProvider>{children}</MultiSelectProvider>
        </FirebaseProvider>
      </ImpersonationProvider>
    </Suspense>
  )
}
