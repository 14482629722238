'use client'

import { logEvent as analyticsLog, getAnalytics } from '@firebase/analytics'
import {
  TotpMultiFactorGenerator,
  TotpSecret,
  User,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  signInWithCustomToken
} from '@firebase/auth'
import { getFirestore } from '@firebase/firestore'
import { deleteObject, getStorage, ref } from '@firebase/storage'
import { FirebaseApp } from 'firebase/app'
import { MultiFactorError, sendPasswordResetEmail } from 'firebase/auth'
import { last } from 'lodash-es'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { WINDOW, getConfigFromHost } from '../../../../organizations.config'
import firebase_app from '@/core/setupFirebase'
import { useSearchParams } from '@/core/hooks/useSearchParams'
import { BaseProviderState, Initial } from '@/core/base-provider-state'

/**
 * Manages state of currently logged in Firebase User.
 */
export const useFirebase = () => useContext(FirebaseContext)

export interface FirebaseState extends BaseProviderState {
  user?: User | null
  degraded?: boolean
}

export const useFirebaseApi = (firebase: FirebaseApp) => {
  const [state, setState] = useState<FirebaseState>(Initial)

  useEffect(() => {
    const completed = getAuth(firebase).onIdTokenChanged(async res => {
      // update local state∏
      setState(s => ({
        ...s,
        user: res,
        initializing: false
      }))
    })

    return () => completed()
  }, [firebase])

  // useAsyncEffect(async () => {
  //   if (isUndefined(state.user)) return
  //   // update server session
  //   try {
  //     if (state.user) {
  //       const token = await state.user.getIdToken()
  //       await fetch('/api/login', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({ token })
  //       })
  //     } else {
  //       await fetch('/api/logout', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         }
  //       })
  //     }
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }, [state.user])

  const signOut = () => getAuth(firebase).signOut()

  const refresh = () => getAuth(firebase).currentUser?.getIdToken(true)

  const signInCustom = (token: string) =>
    signInWithCustomToken(getAuth(firebase), token)

  const logEvent = (
    eventName: string,
    eventParams?: { [key: string]: any }
  ) => {
    analyticsLog(getAnalytics(firebase), eventName, eventParams)
  }

  const setLanguage = (code?: string) => {
    getAuth(firebase).languageCode = code?.replace('-', '_') || 'en'
  }

  const setApiDegraded = (degraded: boolean) =>
    setState(s => ({ ...s, degraded }))

  const deleteFile = async (path?: string) => {
    if (!path) return
    // get upload path from storage url
    const filepath = last(path?.replace(/%2F/g, '/')?.split('com/o/'))?.split(
      '?'
    )?.[0]
    const db = getStorage(firebase)
    const r = await ref(db, filepath)
    await deleteObject(r)
  }

  const getTotpUri = async (organizationName?: string) => {
    if (!state.user) throw new Error('No firebase user for TOTP flow')
    if (!state?.user?.email!)
      throw new Error('No firebase user email address for TOTP flow')
    if (!state?.user?.emailVerified!)
      throw new Error(
        'No firebase user email address not verified for TOTP flow'
      )

    const multiFactorSession = await multiFactor(state.user).getSession()
    const totpSecret =
      await TotpMultiFactorGenerator.generateSecret(multiFactorSession)
    const totpUri = totpSecret.generateQrCodeUrl(
      state?.user?.email!,
      organizationName || 'Rally Cry'
    )
    return { totpSecret, totpUri }
  }

  const enrollTotp = async (
    totpSecret?: TotpSecret,
    verificationCode?: string
  ) => {
    if (!state.user) throw new Error('No firebase user for TOTP flow')
    if (!totpSecret) throw new Error('No TOTP secret for TOTP flow')
    if (!verificationCode) throw new Error('No verification code')

    const multiFactorAssertion =
      TotpMultiFactorGenerator.assertionForEnrollment(
        totpSecret,
        verificationCode
      )
    await multiFactor(state.user).enroll(multiFactorAssertion, 'TOTP')
  }

  const unenrollTotp = async (uid: string) => {
    if (!state.user) throw new Error('No firebase user for TOTP unenroll flow')

    await multiFactor(state.user).unenroll(uid)
  }

  const getMfaHints = () => {
    if (!state.user) throw new Error('No firebase user for TOTP hints')

    const multiFactorSession = multiFactor(state.user)

    return multiFactorSession?.enrolledFactors
  }

  const verifyTotp = async (
    error: MultiFactorError,
    verificationCode?: string
  ) => {
    if (!verificationCode) throw new Error('No verification code')

    const mfaResolver = getMultiFactorResolver(getAuth(firebase), error)
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      mfaResolver.hints[0].uid, // we only support one mfa attached at a time
      verificationCode
    )

    await mfaResolver.resolveSignIn(multiFactorAssertion)
  }

  const sendPasswordReset = async (email: string, redirect: string) => {
    const auth = getAuth(firebase)
    await sendPasswordResetEmail(auth, email, {
      url: window.location.origin + redirect
    })
  }

  const firestore = getFirestore(
    firebase,
    process.env.NEXT_PUBLIC_FIRESTORE_DB!
  )

  return {
    ...state,
    firestore,
    signOut,
    refresh,
    logEvent,
    signInCustom,
    setLanguage,
    setApiDegraded,
    deleteFile,
    firebaseApiKey: firebase.options.apiKey,
    firebase,
    getTotpUri,
    enrollTotp,
    unenrollTotp,
    getMfaHints,
    verifyTotp,
    sendPasswordReset
  }
}

// Exported for Storybook Mocking only
export type FirebaseType = ReturnType<typeof useFirebaseApi>
export const FirebaseContext = createContext(Initial as FirebaseType)
export const FirebaseProvider = ({
  ...props
}: {
  children?: React.ReactNode
}) => {
  const value = useFirebaseApi(
    firebase_app(getConfigFromHost(WINDOW?.location?.host).firebase!)
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const rcToken = searchParams.get('rcToken')

  const { signInCustom, signOut } = value
  useEffect(() => {
    if (rcToken) {
      signInCustom(rcToken)
      setSearchParams({ rcToken: '' })
    }
    // by convention we sign out if rcToken is empty string
    else if (rcToken === '') {
      signOut()
      setSearchParams({ rcToken: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rcToken])

  if (value.initializing || rcToken) return null

  return (
    <FirebaseContext.Provider value={value}>
      {props.children}
    </FirebaseContext.Provider>
  )
}

export const UNAUTHENTICATED = 'UNAUTHENTICATED'
