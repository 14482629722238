'use client'

import {
  useLocalStorage as useLocalStorageValue,
  useSessionStorage as useSessionStorageValue
} from '@mantine/hooks'

/*
 * Wrapper around local storage with error protection.
 */
// eslint-disable react-hooks/rules-of-hooks
export const useLocalStorage = <T>(
  props: Parameters<typeof useLocalStorageValue<T>>[0]
) => useLocalStorageValue<T>({ ...props, getInitialValueInEffect: false })

/*
 * Wrapper around session storage with error protection.
 */
export const useSessionStorage = <T>(
  props: Parameters<typeof useSessionStorageValue<T>>[0]
) => useSessionStorageValue<T>({ ...props, getInitialValueInEffect: false })
