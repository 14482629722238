import { FirebaseOptions } from 'firebase/app'

export const devFirebaseConfig: FirebaseOptions & { stackdriver?: string } = {
  apiKey: 'AIzaSyDX-lQS2NK3kjMHhNjNy9t9tu_QoSv-3QY',
  authDomain: 'auth.dev.rallycryapp.com',
  databaseURL: 'https://rally-cry-dev.firebaseio.com',
  projectId: 'rally-cry-dev',
  storageBucket: 'rally-cry-dev.appspot.com',
  messagingSenderId: '743647630316',
  appId: '1:743647630316:web:6ddd8b8175a16d87b926c9',
  measurementId: 'G-X3RXDR4RE0',
  stackdriver: 'AIzaSyAGl3c6sWUyxCiHh5c3u_0EFX4RytF1_54'
}

export const rallyCryFirebaseConfig = {
  apiKey: 'AIzaSyCIPAAOrBtFe8jN3kT4RFsZEhHbZB5Buk4',
  // authDomain: 'rally-cry.firebaseapp.com',
  authDomain: 'auth.rallycryapp.com',
  databaseURL: 'https://rally-cry.firebaseio.com',
  projectId: 'rally-cry',
  storageBucket: 'rally-cry.appspot.com',
  messagingSenderId: '590668323850',
  appId: '1:590668323850:web:d2d6f7bdec7773dc5093f3',
  measurementId: 'G-KTQZTBME6K',
  stackdriver: 'AIzaSyBh-AoDxwkrx8Z5t5zFeDQvBwASdhszM8I'
}

export const afgFirebaseConfig = {
  apiKey: 'AIzaSyC9gulCUpzMPIHeT1WERtoa8Md1j7nlOJU',
  // authDomain: 'rally-cry-afg.firebaseapp.com',
  authDomain: 'auth.defensegaming.com',
  databaseURL: 'https://rally-cry-afg.firebaseio.com',
  projectId: 'rally-cry-afg',
  storageBucket: 'rally-cry-afg.appspot.com',
  messagingSenderId: '1020811446757',
  appId: '1:1020811446757:web:5ce1fe38e286a08eb0470c',
  measurementId: 'G-WFK9PH6FKB',
  stackdriver: 'AIzaSyDYnpUm10zAKyR_cTpaHiwr-lMFX0ltPX8'
}
