'use client'

import { some } from 'lodash-es'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { BaseProviderState, Initial } from '@/core/base-provider-state'

/**
 * Wrapper to allow MultiSelect state access without prop drilling.
 */
export const useMultiSelect = () => useContext(MultiSelect)

interface MultiSelectState extends BaseProviderState {
  isSelecting: boolean
  selected: number[]
}

const useMultiSelectApi = () => {
  const [state, setState] = useState<MultiSelectState>({
    ...Initial,
    isSelecting: false,
    selected: []
  })

  const toggleSelecting = useCallback(
    (isSelecting: boolean) =>
      setState(s => ({ ...s, isSelecting, selected: [] })),
    []
  )

  const isSelected = useCallback(
    (id: number) => some(state.selected, it => it === id),
    [state]
  )

  const toggleSelected = useCallback((id: number) => {
    setState(s => ({
      ...s,
      selected: some(s.selected, it => it === id)
        ? s.selected.filter(it => it !== id)
        : [...s.selected, id]
    }))
  }, [])

  return {
    ...state,
    toggleSelecting,
    isSelected,
    toggleSelected
  }
}

// Exported for Storybook Mocking only
export type MultiSelectType = ReturnType<typeof useMultiSelectApi>
export const MultiSelect = createContext(Initial as MultiSelectType)
export const MultiSelectProvider = ({
  children
}: {
  children?: React.ReactNode
}) => {
  const value = useMultiSelectApi()
  return <MultiSelect.Provider value={value}>{children}</MultiSelect.Provider>
}
