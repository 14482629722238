import { FirebaseOptions } from 'firebase/app'
import {
  afgFirebaseConfig,
  devFirebaseConfig,
  rallyCryFirebaseConfig
} from './firebase-configs'

export const ORGANIZATIONS: Record<
  string,
  {
    key?: string
    api?: string
    social?: string
    universal?: string
    strapi?: string
    firebase?: FirebaseOptions & { stackdriver?: string }
    localhost?: boolean
  }
> = {
  'localhost:3000': {
    localhost: true,
    key: process.env.NEXT_PUBLIC_ROOT_ORG || undefined,
    strapi: 'afg',
    firebase: devFirebaseConfig,
    api: process.env.NEXT_PUBLIC_API_URL || 'https://api.dev.rallycry.gg',
    social:
      process.env.NEXT_PUBLIC_SOCIAL_URL || 'https://social.dev.rallycry.gg',
    universal:
      process.env.NEXT_PUBLIC_UNIVERSAL_URL ||
      'https://universal.dev.rallycryapp.com'
  },
  'rally-cry-dev.vercel.app': {
    firebase: devFirebaseConfig,
    api: 'https://api.dev.rallycry.gg',
    social: 'https://social.dev.rallycry.gg',
    universal: 'https://universal.dev.rallycryapp.com'
  },
  'rally-cry-dev-afg.vercel.app': {
    key: 'afg',
    strapi: 'afg',
    firebase: devFirebaseConfig,
    api: 'https://api.dev.rallycry.gg',
    social: 'https://social.dev.rallycry.gg',
    universal: 'https://universal.dev.rallycryapp.com'
  },
  'rally-cry-staging.vercel.app': {
    firebase: rallyCryFirebaseConfig,
    api: 'https://latest---api-suite-g5mdxi7aya-uc.a.run.app',
    social: 'https://social.rallycryapp.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'rally-cry-staging-afg.vercel.app': {
    key: 'afg',
    strapi: 'afg',
    firebase: afgFirebaseConfig,
    api: 'https://latest---api-suite-lkkocxb7ya-uc.a.run.app',
    social: 'https://social.airforcegaming.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'rallycry.gg': {
    firebase: rallyCryFirebaseConfig,
    api: 'https://api.rallycry.gg',
    social: 'https://social.rallycry.gg',
    universal: 'https://universal.rallycryapp.com'
  },
  'airforcegaming.com': {
    key: 'afg',
    strapi: 'afg',
    firebase: afgFirebaseConfig,
    api: 'https://api.airforcegaming.com',
    social: 'https://social.airforcegaming.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'defensegaming.com': {
    key: 'defense-gaming',
    strapi: 'afg',
    firebase: afgFirebaseConfig,
    api: 'https://api.defensegaming.com',
    social: 'https://social.defensegaming.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'dafsports.com': {
    key: 'dafsports',
    strapi: 'afg',
    firebase: afgFirebaseConfig,
    api: 'https://api.dafsports.com',
    social: 'https://social.dafsports.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'play.usacademicesports.com': {
    key: 'usael',
    strapi: 'usael',
    firebase: rallyCryFirebaseConfig,
    api: 'https://api.usacademicesports.com',
    social: 'https://social.rallycryapp.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'ecac.gg': {
    key: 'ecac',
    firebase: rallyCryFirebaseConfig,
    api: 'https://api.ecac.gg',
    social: 'https://social.rallycryapp.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'suny.ecac.gg': {
    key: 'suny',
    firebase: rallyCryFirebaseConfig,
    api: 'https://api.ecac.gg',
    social: 'https://social.rallycryapp.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'tournaments.prizepicks.com': {
    key: 'prizepicks',
    firebase: rallyCryFirebaseConfig,
    api: 'https://api.tournaments.prizepicks.com',
    social: 'https://social.rallycryapp.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'hbcutournament.nfl.com': {
    key: 'hbcu',
    firebase: rallyCryFirebaseConfig,
    api: 'https://api.hbcutournament.nfl.com',
    social: 'https://social.hbcutournament.nfl.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'rallycryapp.com': {
    key: 'play',
    firebase: rallyCryFirebaseConfig,
    api: 'https://api.rallycryapp.com',
    social: 'https://social.rallycryapp.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'playnaraka.com': {
    key: 'narakabladepoint',
    firebase: rallyCryFirebaseConfig,
    api: 'https://api.playnaraka.com',
    social: 'https://social.playnaraka.com',
    universal: 'https://universal.rallycryapp.com'
  },
  'compete.playstormgate.com': {
    key: 'stormgate',
    firebase: rallyCryFirebaseConfig,
    api: 'https://api.playstormgate.com',
    social: 'https://social.playstormgate.com',
    universal: 'https://universal.rallycryapp.com'
  }
}

export const WINDOW = typeof window == 'undefined' ? ({} as Window) : window
export const getConfigFromHost = (host?: string | null) => {
  const configLookup =
    ORGANIZATIONS[host?.replace('www.', '') || ''] ||
    ORGANIZATIONS['rally-cry-dev.vercel.app']
  return configLookup
}
