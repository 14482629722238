import {
  useSearchParams as useNextSearchParams,
  usePathname,
  useRouter
} from 'next/navigation'
import { useCallback } from 'react'

export const useSearchParams = () => {
  const searchParams = useNextSearchParams()
  const router = useRouter()
  const pathname = usePathname()

  const setSearchParams = useCallback(
    (
      query?: string | Record<string, any>,
      options?: {
        replace?: boolean // replace the url when navigating (vs push)
        merge?: boolean // merge the new search params with the existing ones (vs full replacement)
      }
    ) => {
      const finalSearchParams = new URLSearchParams()

      if (options?.merge) {
        const currentSearchParams = new URLSearchParams(searchParams.toString())

        currentSearchParams.forEach((value, key) => {
          value
            ? finalSearchParams.append(key, value)
            : finalSearchParams.delete(key)
        })
      }

      // convert query object into a pairs array of [key, value]
      // if the value is an array, map it to a pair of [key, value] for each item
      // then flatten the array
      const pairs = Object.entries(query || {})
        .flatMap(([key, value]) =>
          Array.isArray(value) ? value.map(v => [key, v]) : [[key, value]]
        )
        .filter(([_, value]) => !!value)

      const newSearchParams = new URLSearchParams(pairs)

      newSearchParams.forEach((value, key) => {
        value
          ? finalSearchParams.append(key, value)
          : finalSearchParams.delete(key)
      })

      options?.replace
        ? router.replace(`${pathname}?${finalSearchParams.toString()}`, {
            scroll: false
          })
        : router.push(`${pathname}?${finalSearchParams.toString()}`, {
            scroll: true
          })
    },
    [router, pathname, searchParams]
  )

  return [searchParams, setSearchParams] as const
}
