'use client'

import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import React, { createContext, useContext, useState } from 'react'
import { BaseProviderState, Initial } from '@/core/base-provider-state'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'

/**
 * Manages Impersonation Status
 */
export const useImpersonation = () => useContext(ImpersonationContext)

interface ImpersonationState extends BaseProviderState {
  impersonation?: UserResource | null
  organization?: number | null
  inheritPermissions: boolean
  isProfile?: boolean
}

const useImpersonationApi = (
  state: ImpersonationState | undefined,
  setState: (
    val:
      | ImpersonationState
      | ((prevState: ImpersonationState) => ImpersonationState)
  ) => void
) => {
  const setImpersonation = (props: {
    impersonation?: UserResource | null
    organization?: number | null
    isProfile?: boolean
  }) => setState(s => ({ ...s, ...props }))

  const stopImpersonation = async () =>
    setState(s => ({
      ...s,
      impersonation: null,
      organization: null,
      isProfile: false
    }))

  const toggleInheritPermissions = () =>
    setState(s => ({
      ...s,
      inheritPermissions: !s.inheritPermissions
    }))

  return {
    ...state,
    setImpersonation,
    stopImpersonation,
    toggleInheritPermissions
  }
}

// Exported for Storybook Mocking only
export type ImpersonationType = ReturnType<typeof useImpersonationApi>
export const ImpersonationContext = createContext(Initial as ImpersonationType)
export const ImpersonationProvider = (props: {
  children?: React.ReactNode
  temporary?: boolean
}) => {
  const [state, setState] = useLocalStorage<ImpersonationState>({
    key: 'imp',
    defaultValue: {
      ...Initial,
      inheritPermissions: false,
      isProfile: false
    } as ImpersonationState
  })
  const [temporary, setTemporary] = useState({
    ...Initial,
    inheritPermissions: false,
    isProfile: false
  } as ImpersonationState)

  const value = useImpersonationApi(
    props.temporary ? temporary : state,
    props.temporary ? setTemporary : setState
  )
  return (
    <ImpersonationContext.Provider value={value}>
      {props.children}
    </ImpersonationContext.Provider>
  )
}
