'use client'

import { useParams } from 'next/navigation'
import { WINDOW, getConfigFromHost } from '../../../organizations.config'

export const useConfig = () => {
  const location = useParams()
  const pathOrg = location?.organizationKey as string
  const configLookup = getConfigFromHost(WINDOW?.location?.host)

  const rootOrg: string = configLookup?.key || ''
  const rootMode = !!rootOrg
  const orgKey = (rootOrg || pathOrg)?.toLocaleLowerCase()

  return {
    api: configLookup?.api || '',
    social: configLookup?.social || '',
    universal: configLookup?.universal || '',
    rootMode,
    orgKey,
    isDevelopment: configLookup?.localhost,
    projectId: configLookup.firebase?.projectId,
    stackdriver: configLookup?.firebase?.stackdriver
  }
}
