import i18n from 'i18next'
import Backend from 'i18next-chained-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { frCA } from 'date-fns/locale'

export const initializeTranslations = (suspense: boolean) => {
  // register additional locales for react-datepicker
  registerLocale('pt-BR', ptBR)
  registerLocale('fr-CA', frCA)

  return (
    i18n
      // load translation using xhr -> see /public/locales
      // learn more: https://github.com/i18next/i18next-http-backend
      .use(Backend)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://wwwi18next.com/overview/configuration-options
      .init({
        backend: {
          backends: [HttpApi],
          backendOptions: [
            {
              loadPath: '/locales/{{lng}}/{{ns}}.json'
            }
          ]
        },
        ns: ['translation'],
        supportedLngs: ['en', 'pt-BR', 'fr-CA', 'de-DE'],
        defaultNS: 'translation',
        load: 'currentOnly',
        returnEmptyString: false,
        fallbackLng: 'en',
        react: { useSuspense: suspense },
        interpolation: {
          escapeValue: false // not needed for react as it escapes by default
        }
      })
  )
}
